$colors: (
        #53ff00,  // Green
        #00ff00,  // Light Green
        #0099ff,  // Blue
        #6800ff,  // Purple
        #ff00ff,  // Magenta
        #dc9e00,  // Gold
        #ff9900,  // Orange
        #ff0000,  // Red
        #ff0066,  // Pink
        #00ffd7,  // Aqua
        #00ffcc,  // Cyan
        #53ff00   // Green
);

$shadow-opacity: 0.7;
$shadow-size: 3px, 5px, 7px, 9px; // Smaller shadow size

@mixin drop-shadow($colors) {
  filter: drop-shadow(0 0 nth($shadow-size, 1) rgba(nth($colors, 1), $shadow-opacity)),
          drop-shadow(0 0 nth($shadow-size, 2) rgba(nth($colors, 2), $shadow-opacity)),
          drop-shadow(0 0 nth($shadow-size, 3) rgba(nth($colors, 3), $shadow-opacity)),
          drop-shadow(0 0 nth($shadow-size, 4) rgba(nth($colors, 4), $shadow-opacity));
}

#Home .container h1 {
  font-weight: 900;
  font-size: 6.25vw;
  background: linear-gradient(90deg, $colors);
  background-size: 1000%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: colorShift 5s linear infinite, shadowShift 5s linear infinite;

  @include drop-shadow($colors);

  @keyframes colorShift {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 50% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shadowShift {
    0%, 100%{
      filter: drop-shadow(0 0 3px rgba(#53ff00, $shadow-opacity))
      drop-shadow(0 0 5px rgba(#00ff00, $shadow-opacity))
      drop-shadow(0 0 7px rgba(#0099ff, $shadow-opacity))
      drop-shadow(0 0 9px rgba(#6800ff, $shadow-opacity));
    }
    50% {
      filter: drop-shadow(0 0 3px rgba(#ff00ff, $shadow-opacity))
      drop-shadow(0 0 5px rgba(#dc9e00, $shadow-opacity))
      drop-shadow(0 0 7px rgba(#ff9900, $shadow-opacity))
      drop-shadow(0 0 9px rgba(#ff0000, $shadow-opacity));
    }
  }
}
